var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "authority"
  }, [_c("div", {
    staticClass: "button-box clearflex"
  }, [_c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.addAuthority("0");
      }
    }
  }, [_vm._v("新增角色")])], 1), _c("el-table", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.tableData,
      "tree-props": {
        children: "children",
        hasChildren: "hasChildren"
      },
      border: "",
      "row-key": "authorityId",
      stripe: ""
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "角色id",
      "min-width": "180",
      prop: "authorityId"
    }
  }), _c("el-table-column", {
    attrs: {
      label: "角色名称",
      "min-width": "180",
      prop: "authorityName"
    }
  }), _c("el-table-column", {
    attrs: {
      fixed: "right",
      label: "操作",
      width: "460"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            size: "small",
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.opdendrawer(scope.row);
            }
          }
        }, [_vm._v("设置权限")]), _c("el-button", {
          attrs: {
            icon: "el-icon-plus",
            size: "small",
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.addAuthority(scope.row.authorityId);
            }
          }
        }, [_vm._v("新增子角色")]), _c("el-button", {
          attrs: {
            icon: "el-icon-copy-document",
            size: "small",
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.copyAuthority(scope.row);
            }
          }
        }, [_vm._v("拷贝")]), _c("el-button", {
          attrs: {
            icon: "el-icon-edit",
            size: "small",
            type: "primary"
          },
          on: {
            click: function click($event) {
              return _vm.editAuthority(scope.row);
            }
          }
        }, [_vm._v("编辑")]), _c("el-button", {
          attrs: {
            icon: "el-icon-delete",
            size: "small",
            type: "danger"
          },
          on: {
            click: function click($event) {
              return _vm.deleteAuth(scope.row);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  })], 1), _c("el-dialog", {
    attrs: {
      title: _vm.dialogTitle,
      visible: _vm.dialogFormVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogFormVisible = $event;
      }
    }
  }, [_c("el-form", {
    ref: "authorityForm",
    attrs: {
      model: _vm.form,
      rules: _vm.rules
    }
  }, [_c("el-form-item", {
    attrs: {
      label: "父级角色",
      prop: "parentId"
    }
  }, [_c("el-cascader", {
    attrs: {
      disabled: _vm.dialogType == "add",
      options: _vm.AuthorityOption,
      props: {
        checkStrictly: true,
        label: "authorityName",
        value: "authorityId",
        disabled: "disabled",
        emitPath: false
      },
      "show-all-levels": false,
      filterable: ""
    },
    model: {
      value: _vm.form.parentId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "parentId", $$v);
      },
      expression: "form.parentId"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "角色ID",
      prop: "authorityId"
    }
  }, [_c("el-input", {
    attrs: {
      disabled: _vm.dialogType == "edit",
      autocomplete: "off"
    },
    model: {
      value: _vm.form.authorityId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "authorityId", $$v);
      },
      expression: "form.authorityId"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "角色姓名",
      prop: "authorityName"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off"
    },
    model: {
      value: _vm.form.authorityName,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "authorityName", $$v);
      },
      expression: "form.authorityName"
    }
  })], 1)], 1), _c("div", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: _vm.closeDialog
    }
  }, [_vm._v("取 消")]), _c("el-button", {
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.enterDialog
    }
  }, [_vm._v("确 定")])], 1)], 1), _vm.drawer ? _c("el-drawer", {
    attrs: {
      visible: _vm.drawer,
      "with-header": false,
      size: "40%",
      title: "角色配置"
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.drawer = $event;
      }
    }
  }, [_c("el-tabs", {
    staticClass: "role-box",
    attrs: {
      "before-leave": _vm.autoEnter,
      type: "border-card"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "角色菜单"
    }
  }, [_c("Menus", {
    ref: "menus",
    attrs: {
      row: _vm.activeRow
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "角色api"
    }
  }, [_c("apis", {
    ref: "apis",
    attrs: {
      row: _vm.activeRow
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      label: "资源权限"
    }
  }, [_c("Datas", {
    ref: "datas",
    attrs: {
      authority: _vm.tableData,
      row: _vm.activeRow
    }
  })], 1)], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };